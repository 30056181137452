import Vue from 'vue'
import Router from 'vue-router'

import PageNotFound from '../views/pageNotFound.vue'
import Login from '../components/authorization/login.vue'
import SelectViewMode from '../components/dont_use_dataviewer/landingpage.vue'
import Dataviewer from '../components/dont_use_dataviewer/container.vue'

import ChangePassword from '../components/authorization/change_password.vue'
import UserProfile from '../components/authorization/userProfile.vue'
import ForgotPassword from '../components/authorization/forgot_password.vue'


import ResetUserPassword from '../components/authorization/reset_user_password.vue'
import ResetPassword from '../components/authorization/reset_password.vue'
import ObjectManagement from '../components/object_management/processDefListNew.vue'
import ClientSettings from '../components/configuration/client_settings.vue'
import ManageAccount from '../components/configuration/manage_account.vue'
import FileUpload from "../components/object_management/import_package.vue"
// import LicenseFileUpload from "../components/authorization/import_license_file.vue"
import PublishHistory from '../components/object_management/publish_history.vue'
import ClearCache from "../components/object_management/clear_cache.vue"

import Environmentlist from '../components/edcenvironment/environmentlistNew.vue'
import ManageEnvironment from '../components/edcenvironment/manageenvironment.vue'
import erpAcceleratorList from '../components/edcenvironment/erpAcceleratorList.vue'
import manageErpAccelerator from '../components/edcenvironment/manageErpAccelerator.vue'

import datasourcelist from '../components/configuration/datasourceListNew.vue'
import ManageDataSource from '../components/configuration/manageDatasource.vue'
import dblinkMapping from '../components/configuration/dblinkMapping.vue'

import userlist from '../components/UserManagement/userListNew.vue'
import usergrouplist from '../components/UserManagement/userGroupList.vue'
import manageUserGroup from '../components/UserManagement/addUserGroup.vue'
import AddUser from '../components/UserManagement/manage_user.vue'
import UpdateUser from '../components/UserManagement/manage_user.vue'
import deleteduserlist from '../components/UserManagement/deleteduserlist.vue'


import ObjBasedRoleList from '../components/RoleManagement/ObjectBasedRole/ObjBasedRoleList.vue'
import ManageObjBasedRole from '../components/RoleManagement/ObjectBasedRole/ManageObjBasedRoleRevised.vue'
import rolemapping from '../components/RoleManagement/ObjectBasedRole/roleMappingList.vue'
import roleReport from '../components/RoleManagement/ObjectBasedRole/objectBasedReport.vue'

import ActionBasedRoleList from '../components/RoleManagement/ActionBasedRole/ActionBasedRoleList.vue'
import ManageActionBasedRole from '../components/RoleManagement/ActionBasedRole/ManageActionBasedRole.vue'

import UserRoleSet from '../components/configuration/userroleset.vue'
import RoleList from '../components/configuration/RoleList.vue'
import ManageRole from '../components/configuration/ManageRole.vue'


import object_list_for_pkg from '../components/configuration/object_list_for_pkg.vue'
import PackageList from "../components/configuration/PackageList.vue"
import PackageObject from "../components/configuration/PackageObject.vue"

import SelectEnvTypePublish from '../components/object_management/select_env_type_publish.vue'
import SelectDsTypePublish from '../components/object_management/select_ds_type_publish.vue'
import PublishRequestDetails from '../components/object_management/publish_request_details.vue'
import PublishRuleList from '../components/object_management/publishrulelist.vue'
import PublishRuleListForDs from '../components/object_management/publishrulelistfords.vue'

import AgentList from '../components/agentmanagement/agentList.vue'
import AgentQueueList from '../components/agentmanagement/agent_queue_list.vue'
import FileExport from '../components/customs/fileExports.vue'

import SMTPSetting from '../components/configuration/configureSMTP.vue'
import ArchivistLander from '../components/pathfinder/archivistLanding.vue'
import actionrolemapping from '../components/RoleManagement/ActionBasedRole/ActionRoleMappingList.vue'
import assignuserenv from '../components/RoleManagement/ActionBasedRole/assignUserEnvList.vue'

import edcPathfinder from '../components/pathfinder/edcPathfinder.vue'
import edcLander from '../components/edcLander.vue'
import widgetLander from "../components/pathfinder/widgetLander"

import manageDashboard from '../layouts/manageDashboard.vue'
import dashboard from '../layouts/dashboardLoader.vue'

import dashboardDesigner from "../components/dashboardManagement/dashboardDesigner.vue"
import revisedWidgetLander from '../components/pathfinder/widgetLanderRevised'
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/Login'
        },
        {
            path: "*",
            component: PageNotFound,
            meta:   {
                breadcrumb: 'Home / pageNotFound'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                breadcrumb: 'Home / Login'
            }
        },
        {
            path: '/userprofile',
            name: 'userprofile',
            component: UserProfile,
            meta: {
                breadcrumb: 'Home / UserProfile'
            }
        },
        {
            path: '/changepassword',
            name: 'ChangePassword',
            component: ChangePassword,
            meta: {
                breadcrumb: 'Home / Change Password'
            }
        },
        {
            path: '/clientsettings',
            name: 'ClientSettings',
            component: ClientSettings,
            meta: {
                breadcrumb: 'Home / ClientSettings'
            }
        },
        {
            path: '/manageaccount',
            name: 'ManageAccount',
            component: ManageAccount,
            meta: {
                breadcrumb: 'Home / Account Preference'
            }
        },
        {
            path: '/deleteduserlist',
            name: 'deleteduserlist',
            component: deleteduserlist,
            meta: {
                breadcrumb: 'Home / Deleted Users List'
            }
        },
        {
            path: '/selectviewmode',
            name: 'SelectViewMode',
            component: SelectViewMode,
            meta: {
                breadcrumb: 'Home / Select View Mode'
            }
        },
        {
            path: '/dataviewer',
            name: 'Dataviewer',
            component: Dataviewer,
            meta: {
                breadcrumb: 'Home / Dataviewer'
            }
        },
        {
            path: '/objectmanagement',
            name: 'objectmanagement',
            component: ObjectManagement,
            meta: {
                breadcrumb: 'Home / Object Mangement'
            }
        },
        {
            path: '/environmentlist',
            name: 'environmentlist',
            component: Environmentlist,
            meta: {
                breadcrumb: 'Home / Environment List'
            }
        },
        {
            path: '/manageenvironment',
            name: 'AddEnvironment',
            component: ManageEnvironment,
            meta: {
                breadcrumb: 'Home / Add Environment'
            }
        },
        {
            path: '/manageenvironment/:env_id/:type',
            name: 'manageenvironment',
            component: ManageEnvironment,
            meta: {
                breadcrumb: 'Home / AddEnvironment'
            }
        },
        {
            path: '/datasourcelist',
            name: 'datasourcelist',
            component: datasourcelist,
            meta: {
                breadcrumb: 'Home / Datasource List'
            }
        },
        {
            path: '/dblinkmapping',
            name: 'dblinkmapping',
            component: dblinkMapping,
            meta: {
                breadcrumb: 'Home / DBLink Mapping'
            }
        },
        {
            path: '/managedatasource',
            name: 'AddDatasource',
            component: ManageDataSource,
            meta: {
                breadcrumb: 'Home / Add Datasource'
            }
        },
        {
            path: '/managedatasource/:datasourceid/:type',
            name: 'managedatasource',
            component: ManageDataSource,
            meta: {
                breadcrumb: 'Home / Data Sources / Edit Data Source'
            }
        },
        {
            path: '/userlist',
            name: 'userlist',
            component: userlist,
            meta: {
                breadcrumb: 'Home / Users List'
            },
            
        },
        {
            path: '/usergrouplist',
            name: 'usergrouplist',
            component: usergrouplist,
            meta: {
                breadcrumb: 'Home / Users Group List'
            }
        },
        {
            path: '/manageusergroup',
            name: 'manageusergroup',
            component: manageUserGroup,
            meta: {
                breadcrumb: 'Home / Manage Users Group'
            }
        },
        {
            path: '/rolemapping',
            name: 'rolemapping',
            component: rolemapping,
            meta: {
                breadcrumb: 'Home / Role Mapping List'
            }
        },
        {
            path: '/manageenvandobjrole',
            name: 'ManageObjBasedRole',
            component: ManageObjBasedRole,
            meta: {
                breadcrumb: 'Home / ManageObjBasedRole'
            }
        },
        {
            path: '/securityreport',
            name: 'rolereport',
            component: roleReport,
            meta: {
                breadcrumb: 'Home / Role Report'
            }
        },
        {
            path: '/userroleset',
            name: 'UserRoleSet',
            component: UserRoleSet,
            meta: {
                breadcrumb: 'Home / userroleset'
            }
        },
        {
            path: '/ManageRole',
            name: 'ManageRole',
            component: ManageRole,
            meta: {
                breadcrumb: 'Home / ManageRole'
            }
        },
        {
            path: '/rolelist',
            name: 'rolelist',
            component: RoleList,
            meta: {
                breadcrumb: 'Home / RoleList'
            }
        },
        {
            path: '/envandobjbasedrolelist',
            name: 'envandobjbasedrolelist',
            component: ObjBasedRoleList,
            meta: {
                breadcrumb: 'Home / EnvAndObjbasedRoleList'
            }
        },
        {
            path: '/adduser',
            name: 'AddUser',
            component: AddUser,
            meta: {
                breadcrumb: 'Home / AddUser'
            }
        },
        {
            path: '/update_user',
            name: 'update_user',
            component: UpdateUser,
            meta: {
                breadcrumb: 'Home / update_user'
            }
        },
        {
            path: '/package_builder',
            name: 'object_list_for_pkg',
            component: object_list_for_pkg,
            meta: {
                breadcrumb: 'Home / Package builder'
            }
        },
        {
            path: '/PackageList',
            name: 'PackageList',
            component: PackageList,
            meta: {
                breadcrumb: 'Home / PackageList'
            }
        },
        {
            path: '/PackageObject',
            name: 'PackageObject',
            component: PackageObject,
            meta: {
                breadcrumb: 'Home / PackageObject'
            }
        },
        {
            path: '/publish_history',
            name: 'PublishHistory',
            component: PublishHistory,
            meta: {
                breadcrumb: 'Home / Publish History'
            }
        },
        {
            path: '/import_package',
            name: 'fileupload',
            component: FileUpload,
            meta: {
                breadcrumb: 'Home / fileupload'
            }
        },
        // {
        //     path: '/add_license',
        //     name: 'LicenseFileUpload',
        //     component: LicenseFileUpload,
        //     meta: {
        //         breadcrumb: 'Home / LicenseFileUpload'
        //     }
        // },
        {
            path: '/clear_cache',
            name: 'ClearCache',
            component: ClearCache,
            meta: {
                breadcrumb: 'Home / ClearCache'
            }
        },
        {
            path: '/select_env_type_publish',
            name: 'SelectEnvTypePublish',
            component: SelectEnvTypePublish,
            meta: {
                breadcrumb: 'Home / select env business obj'
            }
        },
        {
            path: '/select_ds_type_publish',
            name: 'SelectDsTypePublish',
            component: SelectDsTypePublish,
            meta: {
                breadcrumb: 'Home / select ds business obj'
            }
        },
        {
            path: '/publish_request_details',
            name: 'PublishRequestDetails',
            component: PublishRequestDetails,
            meta: {
                breadcrumb: 'Home / Publish request'
            }
        },
		{
            path: '/agent_list',
            name: 'AgentList',
            component: AgentList,
            meta: {
                breadcrumb: 'Home / AgentList'
            }
        },
        {            
            path: '/agent_queue_list',
            name: 'AgentQueueList',
            component: AgentQueueList,
            meta: {
                breadcrumb: 'Home / AgentQueueList'
            }
        },
        {
            path: '/publishruleList',
            name: 'publishruleList',
            component: PublishRuleList,
            meta: {
                breadcrumb: 'Home / Publish Rule List'
            }
        },
        {
            path: '/publishruleListfords',
            name: 'publishruleListfords',
            component: PublishRuleListForDs,
            meta: {
                breadcrumb: 'Home / Publish Rule List'
            }
        },
        {
            path: '/resetuserpassword',
            name: 'ResetUserPassword',
            component: ResetUserPassword,
            meta: {
                breadcrumb: 'Home / Reset User Password'
            }
        },
        {            
            path: '/fileexport',
            name: 'FileExport',
            component: FileExport,
            meta: {
                breadcrumb: 'Home / FileExport'
            }
        },
        {
            path: '/erpacceleratorlist',
            name: 'erpAcceleratorList',
            component: erpAcceleratorList,
            meta: {
                breadcrumb: 'Home / erpAcceleratorList'
            }
        },
        {
            path: '/manageErpAccelerator',
            name: 'manageErpAccelerator',
            component: manageErpAccelerator,
            meta: {
                breadcrumb: 'Home / manageErpAccelerator'
            }
        },
        {
            path: '/smtpsetting',
            name: 'smtpsetting',
            component: SMTPSetting,
            meta: {
                breadcrumb: 'Home / SMTP Setting'
            }
        },
        {
            path: '/archivist_landing',
            name: 'archivist_landing',
            component: ArchivistLander,
            meta: {
                breadcrumb: 'Home / Archivist Landing'
            }
        },
        {
            path: '/pathfinder_product_landing',
            name: 'pathfinder_product_landing',
            component: ArchivistLander,
            meta: {
                breadcrumb: 'Home / Pathfinder Product Landing'
            }
        },
        {
            path:'/edcpathfinder',
            name:'edcpathfinder',
            component:edcPathfinder,
            meta:{
                breadcrumb:'Home / EDC Pathfinder'
            }
        },
        {
            path:'/widgetlander',
            name:"widgetLander",
            component:widgetLander,
            meta:{
                breadcrumb:'Home/Widget Lander'
            }
        },
        {
            path:'/callFromWidget',
            name:"revisedWidgetLander",
            component:revisedWidgetLander,
            meta:{
                breadcrumb:'Home/Revised Widget Lander'
            }
        },
        {
            path:'/edclander',
            name:'edclander',
            component:edcLander,
            meta:{
                breadcrumb:'Home / EDC Lander'
            }
        },
        {
            path: '/actionbasedrolelist',
            name: 'actionbasedrolelist',
            component: ActionBasedRoleList,
            meta: {
                breadcrumb: 'Home / ActionBasedRoleList'
            }
        },
        {
            path: '/manageactionbasedrole',
            name: 'ManageActionBasedRole',
            component: ManageActionBasedRole,
            meta: {
                breadcrumb: 'Home / ManageActionBasedRole'
            }
        },
        {
            path: '/actionrolemapping',
            name: 'actionrolemapping',
            component: actionrolemapping,
            meta: {
                breadcrumb: 'Home / Action Role Mapping List'
            }
        },
        {
            path: '/assignuserenv',
            name: 'assignuserenv',
            component: assignuserenv,
            meta: {
                breadcrumb: 'Home / Assigned User Environment List'
            }
        },
        {
            path: '/forgot',
            name: 'ResetPassword',
            component: ResetPassword,
            meta: {
                breadcrumb: 'Home / ResetPassword'
            }
        },
        {
            path: '/forgotpassword',
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                breadcrumb: 'Home / ForgotPassword'
            }
        },
        {
            path:"/manage_dashboard",
            name:"manageDashboard",
            component:manageDashboard,
            meta:{
                breadcrumb:"Home / Manage Dashboard"
            }
        },
        {
            path:"/dashboard",
            name:"dashboard",
            component:dashboard,
            meta:{
                breadcrumb:"Home / Dashboard"
            }
        },
        {
            path:"/dashboard_designer",
            name:"dashboardDesigner",
            component:dashboardDesigner,
            meta:{
                breadcrumb:"Home / Dashboard Designer"
            }
        },
        
    ]
})
