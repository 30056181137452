<template>
  <v-container :class="{EDCHeaderContainer:!isGrayTheme,EDCHeaderContainerGray:isGrayTheme}">
    <v-row class="mb-12 topLines" align="center" dense no-gutters>
      <v-col  cols="3">
        <v-row class="EDC-Row">
          <v-col cols="3" v-if="!isGrayTheme" class="EDC-PageHeader primary--text" style="text-align: left !important;" @click="routeUrl('/edcpathfinder');isSelected=''">
            <span style="cursor:pointer !important;" @click="clearGrid();routeUrl('/edcpathfinder');isSelected=''">PathFinder</span>
          </v-col>
          <v-col v-else cols="3" class="EDC-PageHeader graythmprimary--text" style="text-align: left !important;" @click="routeUrl('/edcpathfinder');isSelected=''">
            <span style="cursor:pointer !important;" @click="clearGrid();routeUrl('/edcpathfinder');isSelected=''">PathFinder</span>
          </v-col>
          <!-- <v-col cols="9" class="EDC-Col" v-if="showSearchEngine && isDataviewerGridObjLoaded && !isDataviewerOpenedInSplit">
            <edc-search-engine key="onHeader" style="padding-top:12px !important;" :theme="isGrayTheme?'grey':'light'" :isFromHeader="true"></edc-search-engine>
          </v-col>
          <v-col v-else :class="{normal:isSelected !== 'Home',focus:isSelected == 'Home'}" @click="routeUrl('/dataviewer')" align="left">
          <v-menu offset-y class="menuCss">
            <template v-slot:activator="{ on }">
            <v-btn text small :class="{normal:isSelected !== 'Home',focus:isSelected == 'Home','primary--text':isSelected==='Home' && !isGrayTheme,'secondary--text':isSelected!='Home'  && !isGrayTheme,'graythmprimary--text':isSelected==='Home' && isGrayTheme,'graythmsecondary--text':isSelected!='Home' && isGrayTheme }" slot="activator" @click="isSelected='Home',routeUrl('/dataviewer')" v-on="on">
              <svgicon name="Menu_Home" :original="true" style="margin-bottom:5%"></svgicon>
              <span> My Data </span>
            </v-btn>
          </template>
          </v-menu>
        </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="7">
       <v-row class="mb-12" dense no-gutters v-if="!hideHeader">
        <v-col :class="{normal:isSelected !== 'Home',focus:isSelected == 'Home'}" @click="routeUrl('/dashboard_designer')">
          <v-menu offset-y class="menuCss">
            <template v-slot:activator="{ on }">
            <v-btn text small :class="{normal:isSelected !== 'Home',focus:isSelected == 'Home','primary--text':isSelected==='Home' && !isGrayTheme,'secondary--text':isSelected!='Home'  && !isGrayTheme,'graythmprimary--text':isSelected==='Home' && isGrayTheme,'graythmsecondary--text':isSelected!='Home' && isGrayTheme }" slot="activator" @click="isSelected='Home',routeUrl('/dashboard_designer')" v-on="on">
              <svgicon name="Menu_Home" :original="true" style="margin-bottom:5%"></svgicon>
              <span> Home</span>
            </v-btn>
          </template>
          </v-menu>
        </v-col>
        <v-col :class="{normal:isSelected !== 'Archive',focus:isSelected == 'Archive'}">
          <v-menu offset-y class="menuCss">
            <template v-slot:activator="{ on }">
              <v-btn   v-show="showDevlop" text small :class="{normal:isSelected !== 'Archive',focus:isSelected == 'Archive','primary--text':isSelected==='Archive' && !isGrayTheme,'secondary--text':isSelected!='Archive'  && !isGrayTheme,'graythmprimary--text':isSelected==='Archive' && isGrayTheme,'graythmsecondary--text':isSelected!='Archive' && isGrayTheme}" slot="activator" @click="isSelected='Archive'" v-on="on">
                <svgicon name="Menu_Develop" :original="true" style="margin-bottom:3%"></svgicon>
                <span>Develop</span>
              </v-btn>
            </template>
            <v-list style="cursor:pointer" v-show="showDevlop" class="EDC-MenuList">
              <v-list-item v-for="(tile, index) in topNavigation.develop" :key="index" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url)">{{tile.name}}</v-list-tile-title>
              </v-list-item>
            </v-list>
          </v-menu>
       </v-col>
       <v-col :class="{normal:isSelected !== 'Configure',focus:isSelected == 'Configure'}" v-if="isMenuBelongToCurrentProduct('configure')">
          <v-menu offset-y class="menuCss">
            <template v-slot:activator="{ on }">
              <v-btn id="menubtnconfig" v-show="showConfigure" text small :class="{normal:isSelected !== 'Configure',focus:isSelected == 'Configure','primary--text':isSelected==='Configure' && !isGrayTheme,'secondary--text':isSelected!='Configure'  && !isGrayTheme,'graythmprimary--text':isSelected==='Configure' && isGrayTheme,'graythmsecondary--text':isSelected!='Configure' && isGrayTheme}" slot="activator"  @click="isSelected='Configure'" v-on="on">
                <svgicon name="Menu_Configure" :original="true" style="margin-bottom:1%"></svgicon>
                <span> Configurations</span>
              </v-btn>
            </template>
            <v-list style="cursor:pointer" v-show="showConfigure" class="EDC-MenuList">
              <v-list-item v-for="(tile, index) in topNavigation.configure"  :id="tile.id" :key="index" v-if="tile.role" @click="routeUrl(tile.url)">
                <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url)">{{tile.name}}</v-list-tile-title>
              </v-list-item>
              <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showRuleMenu">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on" v-show="showRuleMenu">
                      <v-list-tile-title>Rules</v-list-tile-title>
                      <v-list-tile-action class="justify-end">
                        <v-icon>play_arrow</v-icon>
                      </v-list-tile-action>
                  </v-list-item>
                </template>
                <v-list style="cursor:pointer" class="EDC-MenuList">
                  <v-list-item v-for="(tile, index2) in topNavigation.rule" :key="index2" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                    <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-x class="menuCss" open-on-hover style="width:100%">
                <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-tile-title>Agent</v-list-tile-title>
                  <v-list-tile-action class="justify-end">
                    <v-icon>play_arrow</v-icon>
                  </v-list-tile-action>
                </v-list-item>
              </template>
                <v-list style="cursor:pointer" class="EDC-MenuList">
                  <v-list-item v-for="(tile, index3) in topNavigation.agent" :key="index3" id="menulistitemhover" @click="routeUrl(tile.url)">
                    <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list>
          </v-menu>
        </v-col>

        <v-col :class="{normal:isSelected !== 'Admin',focus:isSelected == 'Admin'}" v-if="isMenuBelongToCurrentProduct('admin')">
          <v-menu offset-y class="menuCss">
            <template v-slot:activator="{ on }">
            <v-btn  id="menubtnadmin" v-show="showAdmin" text small :class="{normal:isSelected !== 'Admin',focus:isSelected == 'Admin','primary--text':isSelected==='Admin' && !isGrayTheme,'secondary--text':isSelected!='Admin'  && !isGrayTheme,'graythmprimary--text':isSelected==='Admin' && isGrayTheme,'graythmsecondary--text':isSelected!='Admin' && isGrayTheme}" slot="activator" @click="isSelected='Admin'" v-on="on">
              <svgicon name="User_Admin" :original="true" style="margin-bottom:3%"></svgicon>
              <span> Admin</span>
            </v-btn>
          </template>
            <v-list style="cursor:pointer" v-show="showAdmin" class="EDC-MenuList">
              <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showAdmin">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on" id="menulstusermanagement"  v-show="showUserMgmtMenu">
                      <v-list-tile-title>User Management</v-list-tile-title>
                      <v-list-tile-action class="justify-end">
                        <v-icon>play_arrow</v-icon>
                      </v-list-tile-action>
                  </v-list-item>
                </template>
                <v-list style="cursor:pointer" class="EDC-MenuList">
                  <v-list-item v-for="(tile, index2) in topNavigation.users" :key="index2" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                    <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showSecurityMenu">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on" v-show="showSecurityMenu">
                      <v-list-tile-title id="menulstobjectbasedsecurity">Security</v-list-tile-title>
                      <v-list-tile-action class="justify-end">
                        <v-icon>play_arrow</v-icon>
                      </v-list-tile-action>
                  </v-list-item>
                </template>
                <v-list style="cursor:pointer" class="EDC-MenuList">
                  <v-list-item :id="tile.id" v-for="(tile, index2) in topNavigation.objsecurity" :key="index2" v-if="tile.role" @click="routeUrl(tile.url)">
                    <v-list-tile-title  :id="tile.id" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showPackageMenu" >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on" v-show="showPackageMenu">
                      <v-list-tile-title id="menulstpkgbuilder">Packages</v-list-tile-title>
                      <v-list-tile-action class="justify-end">
                        <v-icon>play_arrow</v-icon>
                      </v-list-tile-action>
                  </v-list-item>
                </template>
                <v-list style="cursor:pointer" class="EDC-MenuList">
                  <v-list-item :id="tile.id" v-for="(tile, index2) in topNavigation.objpackages" :key="index2" v-if="tile.role" @click="routeUrl(tile.url)">
                    <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showClientSettingMenu">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on"  v-show="showClientSettingMenu">
                      <v-list-tile-title id="menulstpkgbuilder">Client Settings</v-list-tile-title>
                      <v-list-tile-action class="justify-end">
                        <v-icon>play_arrow</v-icon>
                      </v-list-tile-action>
                  </v-list-item>
                </template>
                <v-list style="cursor:pointer" class="EDC-MenuList">
                  <v-list-item :id="tile.id" v-for="(tile, index2) in topNavigation.objclientsettings" :key="index2" v-if="tile.role" @click="routeUrl(tile.url)">
                    <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showClearCacheMenu">
                <template v-slot:activator="{ on }" >
                  <v-list-item v-on="on"  v-show="showClearCacheMenu">
                      <v-list-tile-title id="menulstpkgbuilder" @click="routeUrl('/clear_cache');">Clear Cache</v-list-tile-title>
                  </v-list-item>
                </template>
              </v-menu> -->
              
              <!-- <v-menu offset-x class="menuCss" open-on-hover style="width:100%" v-show="showSecurityMenu">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                      <v-list-tile-title id="menulstobjectbasedsecurity">Administrative Security</v-list-tile-title>
                      <v-list-tile-action class="justify-end">
                        <v-icon>play_arrow</v-icon>
                      </v-list-tile-action>
                  </v-list-item>
                </template>
                <v-list style="cursor:pointer" class="EDC-MenuList">
                  <v-list-item :id="tile.id" v-for="(tile, index2) in topNavigation.adminsecurity" :key="index2" v-if="tile.role" @click="routeUrl(tile.url)">
                    <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url);">{{tile.name}}</v-list-tile-title>
                  </v-list-item>
                </v-list>
              </v-menu> -->
              <v-list-item v-for="(tile, index) in topNavigation.admin" :key="index" v-if="tile.role" id="menulistitemhover" @click="routeUrl(tile.url)">
                <v-list-tile-title  :id="tile.name" @click="routeUrl(tile.url)">{{tile.name}}</v-list-tile-title>
              </v-list-item>
            </v-list>
            
          </v-menu>
          
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2">
      <v-row class="mb-12" dense no-gutters>
        <v-col cols="1"></v-col>
        <v-col cols="11" style="text-align: right !important; padding-right: 20px !important">
          <v-badge color="green" :content="notificationCount.toString()" overlap offset-y="40" right bottom v-if="notificationCount">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" elevation="0" fab text x-small slot="activator" id="smallbtn" v-on="on" @click="routeUrl('/fileexport')">
                <i class="material-icons"  :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="My Notifications" id="smallicon" >notifications_active</i>
              </v-btn>
            </template>
          </v-menu>
        </v-badge>
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn fab text x-small v-on="on" id="smallbtn">
                <v-icon @click="routeUrl('/edcpathfinder')" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="Pathfinder Search" id="smallicon" >mdi-magnify</v-icon>
                <!-- <i class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="My Settings" id="smallicon">language</i> -->
              </v-btn>
            </template>
          </v-menu>
          <v-menu offset-y v-if="notificationCount < 1">
            <template v-slot:activator="{ on }">
              <v-btn :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" elevation="0" fab text x-small v-on="on" id="smallbtn" @click="routeUrl('/fileexport')">
                <i class="material-icons"  :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="My Notifications" id="smallicon">notifications_active</i>
              </v-btn>
            </template>
          </v-menu>
          <!-- <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn fab text x-small v-on="on" id="smallbtn">
                <i class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="My Settings" id="smallicon">language</i>
              </v-btn>
            </template>
          </v-menu> -->

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn fab text x-small v-on="on" id="smallbtn" :title="getUserTitle()">
                <!-- <i class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="Profile Settings" id="smallicon">person</i> -->
                <span id="profile" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}">{{initials}}</span>
              </v-btn>
            </template>
            <v-list style="cursor:pointer" class="EDC-MenuList">
              <v-list-item>
                <v-list-tile-title id="My Downloads" @click="routeUrl('/fileexport')">My Downloads</v-list-tile-title>
              </v-list-item>
               <v-list-item v-if="!hideHeader">
                <v-list-tile-title id="Edit Profile" @click="routeUrl('/userprofile')">Edit Profile</v-list-tile-title>
              </v-list-item>
              <v-list-item v-if="!hideHeader">
                <v-list-tile-title id="Change Password" @click="routeUrl('/changepassword')">Change Password</v-list-tile-title>
              </v-list-item>
              <v-list-item v-if="!hideHeader">
                <v-list-tile-title id="ManageDashboard" @click="routeUrl('/dashboard_designer')">My Dashboard</v-list-tile-title>
              </v-list-item>
              <v-list-item>
                <v-list-tile-title id="Sign out" @click="logout()">Sign out</v-list-tile-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-x :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-btn fab text x-small v-on="on" id="smallbtn" style="border:0px !important">
                <i  class="material-icons" :class="{'graythmprimary--text':isGrayTheme,'primary--text':!isGrayTheme}" title="Applications" id="smallicon">apps</i>
              </v-btn>
            </template>
                <v-list style="cursor:pointer" class="EDC-MenuList" v-if="applicationList.length">
               <v-list-item v-for="(item,idx) in applicationList" :key="item.product_name">
                <v-list-tile-title :id="item.product_name" @click="redirectProduct(item)">{{item.product_name}}</v-list-tile-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>

    <v-snackbar v-model="snackbar" :right=true :top=true :color='colorValue'>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false" > Close </v-btn>
      </template>
    </v-snackbar>
    <simplert :useRadius="true" :useIcon="true" ref="simplert">
    </simplert>
  </v-row>
</v-container>
</template>

<script>
var moment = require("moment-timezone")
import {
  post as postToServer
} from './../methods/serverCall.js';
import config from '../config.json'
import {
  SERVER_ERROR
} from '../data/client_message.js'
import {
  LOGOUT, UPDATE_LAST_USED_PRODUCT
} from '../data/url_constants.js'
import {
  DATASOURCE_SERVICE_ID,PUBLISH_SERVICE_ID,ENVIRONMENT_SERVICE_ID,REPOSITORY_SERVICE_ID,
  USER_PROVISIONING_SERVICE_ID,POLICY_SERVICE,SCHEDULER_SERVICE,ACCOUNT_MANAGEMENT_SERVICE, PACKAGE_BUILDER,
  SECURITY_SERVICE,JOB_MANAGEMENT_SERVICE,JOB_CALENDAR_SERVICE, JOB_PLAN_SERVICE_ID, 
  JDE_FORMATTER_SERVICE_ID, EDC_CACHE_SERVICE, MANAGE_BO, MANAGE_BV, MANAGE_DM, MANAGE_GV,
  DEFAULT_MANAGE_RULE, MANAGE_OBJECT_PUBLISH_RULE, MANAGE_OBJECT_PUBLISH_USING_DS_RULE, MANAGE_REPOSITORY,
  DATASOURCE_MGMT_DEFAULT, ENVIRONMENT_MGMT_DEFAULT, ACCELERATOR_MGMT_DEFAULT, DATASOURCE_MGMT, 
  ENVIRONMENT_MGMT, ERP_ACCELERATOR_MGMT, MANAGE_PACKAGES, MNG_SMTP_SETTING, MNG_CLIENT_SETTING, 
  MNG_INSTANCE_SETTING, MNG_LDAP_SETTING, MANAGE_USER, MANAGE_USER_GROUP, MANAGE_USER_ENV_MAPPING, 
  MANAGE_ACTION_BASED_SECURITY, MANAGE_OBJ_BASED_SECURITY, MANAGE_ADMIN_SECURITY, PUBLISH_HISTORY, MANAGE_TL } from "../data/macros.js"
import {mapActions} from 'vuex'
import '../compiled-icons';
import {Archivist_Product,Workflow_Product,Dataviewer_Product,Ticket_management,Ticket_Management_Domain} from '../constants/constants.js'
import {getURLByDomainByProduct} from './../methods/domain_management.js'
import edcSearchEngine from  './../views/searchengine.vue'
import Simplert from 'vue2-simplert'

export default {
  name: "customHeader",
  components:{
    'edc-search-engine':edcSearchEngine,
    'simplert':Simplert
  },
  watch:{
      '$store.state.dataviewerGridObject':{
        handler(newValue){

        }
      },
      '$store.state.dataviewerProps':{
        handler(newValue){

        }
      },
      '$store.state.themeType':{
      handler(newValue){
        this.isGrayTheme = false
          if(newValue === 'dark')
            this.isGrayTheme = true
        }
      },
      '$store.state.generalDownloadNotification':{
        handler(newVal){
          ++this.notificationCount
          if(newVal.notification_bar_message)
            this.notificationBarList.unshift(newVal.notification_bar_message)
          this.showDownloadNotification(newVal)
        }
      },
      '$store.state.pendingDownloadStatusUpdate':{
            handler(newVal){
              if(!this.clearNotificationBadge){
                ++this.notificationCount
                if(newVal.notification_bar_message)
                  this.notificationBarList.unshift(newVal.notification_bar_message)
              }
            }
        },
        '$route':{
          handler(newVal){
            if(this.clearNotificationBadge){
              this.notificationCount = 0
              this.notificationBarList = []
            }
          }
        },
        'notificationBarList':{
          handler(newVal){
            this.notificationDisplayList = newVal.slice(0,5)
          }
        }
    },
    data() {
      return {
        product_name:Archivist_Product,
        archivist_main_menu:['run','data management','develop','configurations','admin', 'configure'],
        workflow_main_menu:['approve','develop','admin', 'configurations'],
        isGrayTheme:false,
        snackbar:false,
        snackbartext:'',
        snackbartimeout:5000,
        colorValue:'green',
        userRole:this.$session.get('user_role_mapping'),
        have_account_right:this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE),
        configureMenu:false,
        isSelected:'Home',
        home_page: this.$session.get('home_page'),
        topNavigation: {},
          currenttime: '',
          interval: '',
          sinout_endpoint: '',
          user_name: this.$session.get('user_name'),
          time_zone:'',
          showRunMenu:this.getUserMenu(PUBLISH_SERVICE_ID) || this.getUserMenu(JOB_MANAGEMENT_SERVICE) ||this.getUserMenu(SCHEDULER_SERVICE)|| this.getUserMenu(PUBLISH_SERVICE_ID) || this.getUserMenu(JOB_PLAN_SERVICE_ID),
          showDataManagementMenu:this.getUserMenu(JOB_MANAGEMENT_SERVICE),
          showDevlop:this.getUserMenuByAction(PUBLISH_SERVICE_ID, [MANAGE_BO, MANAGE_BV, MANAGE_DM, MANAGE_TL, PUBLISH_HISTORY]) || 
          this.getUserMenuByAction(REPOSITORY_SERVICE_ID, [MANAGE_REPOSITORY]) || 
          this.getUserMenuByAction(PUBLISH_SERVICE_ID, [MANAGE_BO, MANAGE_BV, MANAGE_DM, MANAGE_TL]),
          showConfigure: this.getUserMenuByAction(DATASOURCE_SERVICE_ID, [DATASOURCE_MGMT_DEFAULT, DATASOURCE_MGMT]) ||
          this.getUserMenuByAction(ENVIRONMENT_SERVICE_ID, [ENVIRONMENT_MGMT_DEFAULT, ENVIRONMENT_MGMT]) ||
          this.getUserMenuByAction(JDE_FORMATTER_SERVICE_ID, [ACCELERATOR_MGMT_DEFAULT, ERP_ACCELERATOR_MGMT]) ||
          this.getUserMenuByAction(PUBLISH_SERVICE_ID, [
            DEFAULT_MANAGE_RULE, MANAGE_OBJECT_PUBLISH_RULE, MANAGE_OBJECT_PUBLISH_USING_DS_RULE]),
          showRuleMenu: this.getUserMenuByAction(PUBLISH_SERVICE_ID, 
              [DEFAULT_MANAGE_RULE, MANAGE_OBJECT_PUBLISH_RULE]) || this.getUserMenuByAction(PUBLISH_SERVICE_ID, 
              [DEFAULT_MANAGE_RULE, MANAGE_OBJECT_PUBLISH_USING_DS_RULE]),
          showAdmin:this.getUserMenu(USER_PROVISIONING_SERVICE_ID) ||
            this.getUserMenu(SECURITY_SERVICE) ||
            this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE) ||
            this.getUserMenu(PACKAGE_BUILDER) ||
            this.getUserMenu(EDC_CACHE_SERVICE),
          showPackageMenu: this.getUserMenu(PACKAGE_BUILDER),
          showUserMgmtMenu: this.getUserMenu(USER_PROVISIONING_SERVICE_ID),
          showSecurityMenu:this.getUserMenu(SECURITY_SERVICE),
          showClientSettingMenu: this.getUserMenu(ACCOUNT_MANAGEMENT_SERVICE),
          // showClearCacheMenu: this.getUserMenu(EDC_CACHE_SERVICE),
          initials:'',
          fullName:'',
          notificationCount:0,
          notificationBarList:[],
          notificationDisplayList:[],
          applicationList:[],
          hostName: window.location.hostname
        }
      },
      computed:{
        isArchivistCall(){
				  return !_.isEmpty(this.$store.state.archivistRedirectionObj)
			  },
        hideHeader() {
          return this.isArchivistCall
        },
        clearNotificationBadge(){
          return this.$route.path === '/fileexport'
        },
        showSearchEngine(){
          return this.$route.path === '/edcpathfinder'
        },
        isDataviewerGridObjLoaded(){
          return !_.isEmpty(this.$store.state.dataviewerGridObject)
        },
        isDataviewerOpenedInSplit(){
          return this.isDataviewerGridObjLoaded && this.$store.state.dataviewerGridObject.openModeType === 'split'
        }
      },
      filters: {
        initCap: function (value) {
          if (!value) return ''
            let arr = value.split(' ');
          arr.map((string, index) => {
            string = string.toString()
            arr[index] = string.charAt(0).toUpperCase() + string.slice(1)
          })
          return arr.join(' ');
        }
      },
      beforeCreate(){
        this.userRole = this.$session.get('user_role_mapping');
        this.$session.set('is_superadmin',this.userRole.is_superadmin)
      },
      mounted() {
        this.interval = setInterval(this.gettime, 1000);
        this.home_page = this.$session.get('home_page');
        this.setthemeInfo(this.$session.get('theme_name'))
        this.product_name = window.sessionStorage.getItem('product')
        this.topNavigation = this.getTopNavigation()
        if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase() + this.$session.get('last_name').charAt(0).toUpperCase()
        }
        else{
          this.initials = this.$session.get('first_name').charAt(0).toUpperCase()
        }
        this.getUnreadNotifications()
        this.getApplicationList()
      },
      methods: {
        ...mapActions(['setthemeInfo',"setsameComponentRedirectObj","setDataviewerProps"]),
        getUnreadNotifications(){
          let data = {
            'client_id':this.$session.get('client_id'),
            'username':this.$session.get('email')
          }
          postToServer(this,config.DATA_VIEWER_URL+'/get_unread_notification',data).then(response=>{
            // console.log('unread notification',response)
            this.notificationCount = response.unread_notification
          }).catch(error=>{

          })
        },
        getTopNavigation(){
          return {
          'develop': [
          {
            'name': "Object Management",
            'url': '/objectmanagement',
            role:this.getUserMenuByAction(PUBLISH_SERVICE_ID, [MANAGE_BO, MANAGE_BV, MANAGE_DM, MANAGE_TL])
          },
          {
            'name': "Repository",
            'url': '/objectsinrepo',
            role:this.getUserMenuByAction(REPOSITORY_SERVICE_ID, [MANAGE_REPOSITORY])
          },
          {
            'name': "Publish History",
            'url': '/publish_history',
            role:this.getUserMenuByAction(PUBLISH_SERVICE_ID, [MANAGE_BO, MANAGE_BV, MANAGE_DM, MANAGE_TL, PUBLISH_HISTORY])
          },
          ],
          'configure': [
          {
              name: 'Datasources',
              url: '/datasourcelist',
              role: this.getUserMenuByAction(DATASOURCE_SERVICE_ID, [DATASOURCE_MGMT]),
              id:"menudatasource"
            },
            {
              'name': 'Environments',
              'url': '/Environmentlist',
              role: this.getUserMenuByAction(ENVIRONMENT_SERVICE_ID, [ENVIRONMENT_MGMT]),
              id:"menuenvironment"
            },
            {
              'name': 'DBLink Mapping',
              'url': '/dblinkmapping',
              role: this.getUserMenuByAction(DATASOURCE_SERVICE_ID, [DATASOURCE_MGMT]),
              id:"menudblink"
            },
          {
              name: "Erp Accelerator",
              url: "/erpacceleratorlist",
              role: this.getUserMenuByAction(JDE_FORMATTER_SERVICE_ID, [ERP_ACCELERATOR_MGMT]),
              id:"menuaccelerator"
          },
          ],
            'rule':[
            {
              name: 'Object Publishing',
              url: '/publishrulelist',
              role:this.getUserMenuByAction(PUBLISH_SERVICE_ID, 
              [DEFAULT_MANAGE_RULE, MANAGE_OBJECT_PUBLISH_RULE])
            },
            {
              name: 'Object Publishing Using Datasources',
              url: '/publishrulelistfords',
              role:this.getUserMenuByAction(PUBLISH_SERVICE_ID, 
              [DEFAULT_MANAGE_RULE, MANAGE_OBJECT_PUBLISH_USING_DS_RULE])
            },
            ],
            'agent':[
            {
              name:'Agent List',
              url: '/agent_list',
              role: true
            },
            {
              name:'Agent Queue List',
              url: '/agent_queue_list',
              role: true
            }
            ],
            
          'objpackages': [
            {
              name: 'Package Builder',
              url: '/package_builder',
              role: this.getUserMenuByAction(PACKAGE_BUILDER, [MANAGE_PACKAGES]),
            },
            {
              name: 'Package List',
              url: '/PackageList',
              role: this.getUserMenuByAction(PACKAGE_BUILDER, [MANAGE_PACKAGES]),
            },
            {
              name: 'Import Package',
              url: '/import_package',
              role: this.getUserMenuByAction(PACKAGE_BUILDER, [MANAGE_PACKAGES]),
            },
          ],
          
          'objclientsettings': [
            {
                name: "SMTP",
                url: "/smtpsetting",
                role: this.getUserMenuByAction(ACCOUNT_MANAGEMENT_SERVICE, [MNG_SMTP_SETTING])
            },
            {
              name:'Client Profile',
              url:'/clientsettings',
              role: this.getUserMenuByAction(ACCOUNT_MANAGEMENT_SERVICE, [MNG_CLIENT_SETTING])
            },
          ],
            'admin': [
            {
              name: 'Clear Cache',
              url: '/clear_cache',
              role: this.getUserMenu(EDC_CACHE_SERVICE),
              id:"menuclrcache"
            },
            
            ],
            'users': [
            {
                name: "Users",
                url: "/userlist",
                role: this.getUserMenuByAction(USER_PROVISIONING_SERVICE_ID, [MANAGE_USER])
            },
            {
                name: "User Group",
                url: "/usergrouplist",
                role: this.getUserMenuByAction(USER_PROVISIONING_SERVICE_ID, [MANAGE_USER_GROUP])
            },
            {
            name: "Deleted Users",
            url: "/deleteduserlist",
            role: this.getUserMenuByAction(USER_PROVISIONING_SERVICE_ID, [MANAGE_USER])
          },
             ],
             'security': [
            {
              name: 'Role Setup',
              url: '/rolelist',
              role:this.getUserMenu(SECURITY_SERVICE)
            },
            {
              name: 'User Roles',
              url: '/userroleset',
              role:this.getUserMenu(SECURITY_SERVICE)
            },
             ],
            'objsecurity': [
              {
              name: 'Security Report',
              url: '/securityreport',
              role:true,
              "id":"roleReport"
              },
              {
              name: 'Assign Environment Access',
              url: '/assignuserenv',
              role: this.getUserMenuByAction(SECURITY_SERVICE, [MANAGE_USER_ENV_MAPPING]),
              "id":"menuuserenvmaplist"
              },
              {
                name: "Assign Action Security by Env",
                url: "/envrolemapping",
              role: this.getUserMenuByAction(SECURITY_SERVICE, [MANAGE_ACTION_BASED_SECURITY]),
                "id":"menuobjrolemapping"
              },
              {
                name: "Assign Object Security by Env",
                url: "/objrolemapping",
                role: this.getUserMenuByAction(SECURITY_SERVICE, [MANAGE_OBJ_BASED_SECURITY]),
                "id":"menuobjrolemapping"
              },
              {
              name: 'Assign Admin Security',
              url: '/actionrolemapping',
              role: this.getUserMenuByAction(SECURITY_SERVICE, [MANAGE_ADMIN_SECURITY]),
              "id":"menuassignadministrativerole"
              },
              // {
              //     name: "Security Assignment Report",
              //     url: "/rolereport",
              //     role:this.getUserMenu(SECURITY_SERVICE),
              //     "id":"menuobjrolereport"
              // },
              {
              name: 'Setup Action Security Roles by Env',
              url: '/objenvbasedrole',
              role: this.getUserMenuByAction(SECURITY_SERVICE, [MANAGE_ACTION_BASED_SECURITY]),
              "id":"menuobjbasedrole"
              },
              {
              name: 'Setup Object Security Roles by Env',
              url: '/objbasedrolelist',
              role: this.getUserMenuByAction(SECURITY_SERVICE, [MANAGE_OBJ_BASED_SECURITY]),
              "id":"menuobjbasedrole"
              },
              {
              name: 'Setup Admin Security Roles',
              url: '/actionbasedrolelist',
              role: this.getUserMenuByAction(SECURITY_SERVICE, [MANAGE_ADMIN_SECURITY]),
              "id":"menuactionbasedrole"
              },
              
             ],
             'adminsecurity': [
             ]
          }
        },
        isArchivistProduct(){
          return true
        },
        isWorkflowProduct(){
          return true
        },
        isMenuBelongToCurrentProduct(menu){
          return true
          let menu_list = []
          if(this.product_name === Archivist_Product)
            menu_list = this.archivist_main_menu
          else if(this.product_name === Workflow_Product)
            menu_list = this.workflow_main_menu
          if(menu_list.indexOf(menu)>-1)
            return true
          return false
        },
        getUserMenu(serviceName){
          if(this.userRole.is_superadmin)
            return true
          if(this.userRole[serviceName]){
            return true
          }
          if (serviceName === 'default')
            return true
          return false;
        },
        getUserMenuByAction(serviceName, group_code_list){
          if(this.userRole.is_superadmin)
            return true
          if(this.userRole[serviceName]){
            var all_group = this.userRole[serviceName]
            if(group_code_list.some(value => all_group.includes(value))){
              return true
            }
          }
          return false;
        },
        routeUrl(path) {
          var _this = this
          this.setthemeInfo(this.$session.get('theme_name'))
          // $store.sameComponentRedirectObj used redirect to same component with different parameters.Vue js not allowing to use same page with different param options
          if(this.previousPath===path)
            return
          this.previousPath = path

          setTimeout(()=>{
            _this.previousPath = ''
          },1000)
          if(path === '/edcpathfinder' || (_.isEmpty(this.$store.state.pathfinderObject.left) && _.isEmpty(this.$store.state.pathfinderObject.right))){
              return this.openInSameTab(path)
            }
          else{
            return this.openRouteInDifferentTab(path)
          }
        },
        openInSameTab(path){
          var _this = this
          _this.setsameComponentRedirectObj({})
          if(path === '/objectsinrepo'){
            this.$router.push({"name":"objectmanagement",params:{"isPageForRepo":true}}).catch(()=>{})
            this.setsameComponentRedirectObj({"isPageForRepo":true})
          } else if(path === '/objectmanagement'){
            this.$router.push({"name":"objectmanagement",params:{"isPageForEnv":true}}).catch(()=>{})
            this.setsameComponentRedirectObj({"isPageForEnv":true})
          }else if(path === '/objenvbasedrole'){
            this.$router.push({"name":"envandobjbasedrolelist",params:{"roletype":'environmental'}}).catch(()=>{})
            this.setsameComponentRedirectObj({"roletype":'environmental'})
          }else if(path === '/objbasedrolelist'){
            this.$router.push({"name":"envandobjbasedrolelist",params:{"roletype":'object_based'}}).catch(()=>{})
            this.setsameComponentRedirectObj({"roletype":'object_based'})
          }else if(path === '/envrolemapping'){
            this.$router.push({"name":"rolemapping",params:{"roletype":'environmental'}}).catch(()=>{})
            this.setsameComponentRedirectObj({"roletype":'environmental'})
          }else if(path === '/objrolemapping'){
            this.$router.push({"name":"rolemapping",params:{"roletype":'object_based'}}).catch(()=>{})
            this.setsameComponentRedirectObj({"roletype":'object_based'})
          }
          else{
            this.$router.push(path).catch(()=>{});
          }
        },
        openOption(path){
          let confirmFn = function () {
              this.$parent.openRouteInDifferentTab(path)
          }
          let cancelFn = function () {
              this.$parent.openInSameTab(path)
          }
          let obj = {
              title: 'Open In',
              message: 'Pathfinder data is loaded on the page. Please suggest how do you want to open the new link?',
              type: 'info',
              useConfirmBtn: true,
              onConfirm: confirmFn,
              onClose:cancelFn,
              customCloseBtnText:'Open In Current Tab',
              customConfirmBtnText:'Open In New Tab'
          }
          this.$refs.simplert.openSimplert(obj)
        },
        openRouteInDifferentTab(path){
          var _this = this
          var routeData = this.$router.resolve({"name":"edclander",query:{pathToRedirect:path}});
          window.open(routeData.href, '_blank');
          setTimeout(()=>{
            _this.previousPath = '/edcdataviewer'
          },1000)
        },
        gettime() {
          this.currenttime = moment().tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')
          this.time_zone = moment().tz(this.$session.get('time_zone_preference')).format('z')
        },
        logout() {
          var logout_endpoint = config.AUTHENTICATION_URL + LOGOUT
          var input_token = {
            'access_token': this.$session.get('access_token')
          }

          if(this.hostName){
            this.$cookies.remove('access_token',null,this.hostName)
            this.$cookies.remove('last_selected_env_id',null,this.hostName)
          }

          postToServer(this, logout_endpoint, input_token).then(logout_response => {
            if (this.$cookies.isKey('Timeout')) {
              this.$cookies.remove('Timeout')
            }
            this.$session.destroy()
            window.location.href = "/login"
          }).catch(error_response => {
            if (this.$cookies.isKey('Timeout')) {
              this.$cookies.remove('Timeout')
            }
            this.$session.destroy()
          });
        },
        ReportRedirect(){
          this.$router.push({ name: 'ReportDownloadStatus',
            params:{"frompage": true}})
        },
        redirectProduct(productDetails){
          let _this = this
          // let url = this.$session.get('protocol')+'://'
          let queryString = "&last_env="+this.$session.get('last_selected_env_id')
          let url = getURLByDomainByProduct(_this,productDetails,'login',queryString)
          if(url)
              window.open(url, '_blank');

          setTimeout(function(){
            _this.selectedproduct = ''
          },100)
        },
        getUserTitle(){
          if(this.$session.get('last_name') && this.$session.get('last_name').length > 0){
          this.fullName = this.$session.get('first_name') + " "+ this.$session.get('last_name') + ' | '+this.currenttime +' ('+this.time_zone+')'
          }
          else{
            this.fullName = this.$session.get('first_name') + ' | '+this.currenttime +' ('+this.time_zone+')'
          }
          return this.fullName
        },
        showDownloadNotification(data){
          
        },
        getApplicationList(){
          var _this = this
          let data = {"client_id":this.$session.get('client_id')}
          this.applicationList = []
          postToServer(_this,config.ACCOUNT_MANAGEMENT_URL+'/get_product_configuration',data).then(response=>{
            // temporary setting for CHG for inside product redirection. need to work on proper solution
            console.log("response",response)
            _.forEach(response, (obj)=>{
              if(obj.product_name === 'PATHFINDER')
                obj['host_name'] = window.location.hostname
            })
            this.$session.set('applications_configuration',response)
            _.forEach(response,(obj)=>{
              if(obj.product_name === 'OBJECT_DESIGNER' || obj.product_name === 'PATHFINDER')
                return
              this.applicationList.push(obj)
            })
          }).catch(error=>{
            // alert('error in application fetching')
          })
        },
        clearGrid(){
            ++this.$store.state.resetGridCount
        }
      }
    }
</script>

<style>

    .normal{
      color:rgb(125, 125, 115) !important;
    }
    .focus{
      color:rgb(50,120,150) !important;
      font-weight: bold !important;
    }
    .width-60{
      width:60%
    }
    .menuCss{
      height:100%;
      padding-top: 0px;
      width:120px;
      font-size: 90%;

    }
    .topLine{
     background-color: white;
     color: #A0A0A0;
     line-height: 35px;
     position: fixed;
     top: 0px;
     width: 100%;
     margin: 0px !important;
     z-index: 20;
   }

   .main-header {
    background-color: RGB(51,51,51);
    width: 100%;
    z-index: 20;
    padding-top: 5px;
    margin: 0px;
    position: fixed;
    top: 30px;
    width: 100%;
    height: 66px;
  }

  .cust-logo {
    width: 90%;
    height: 80%;
  }

  .lightgray {
    color: rgb(125, 125, 115) ;
  }

  .v-list__tile:hover{
    background: rgb(51,51,51);
    color: white;
  }
  .activeLink {
    color: rgb(50,120,150) !important;
    font-weight: bold;
  }

  .ht-70 {
    height: 70px;
  }

  #menulistitemhover:hover {
    background-color:black;
    color:white !important;
  }

  .tabTitle {
    font-size: 90%;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .v-tabs__container {
    height: 30px !important;
  }

  .v-btn{
    color:#A0A0A0 !important;
    padding:0 px;
    font-size:14px !important;
    text-transform: none;
  }

  .v-application .mb-12 {
    margin-bottom: 0px !important;
}

#smallbtn {
    height: 25px !important;
    width: 25px !important;
    color: #A0A0A0 !important;
    border: 1px solid #A0A0A0 !important;
    margin: 6px !important;
}

#smallicon {
    font-size: 16px;
}

</style>
