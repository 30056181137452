<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-card class="mainCard">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col">
                            <template v-for="(moduleItem,moduleIndex) in moduleList">
                                <v-card :key="moduleItem.module_code+moduleIndex" class="moduleCard">
                                    <v-card-title class="moduleCardTitle">
                                        <v-row class="EDC-Row">
                                            <v-col class="EDC-Col" align="left" :id="'chk'+moduleItem.module_code">
                                                <v-checkbox class="radioClass" :label="moduleItem.module_name" v-model="moduleItem.isSelected" :indeterminate="moduleItem.isInterminate" @change="changeSelection(moduleItem)" dense hide-details/>
                                            </v-col>
                                            <!-- <v-col class="EDC-Col" cols="1" align="right">
                                                <svgicon  class="svg-icon-grid svg-fill-grid" name="cancel_v2"  :original="true"  title="Close" @click="clearSelection(moduleItem)"></svgicon>
                                            </v-col> -->
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row class="EDC-Row">
                                            <v-col class="EDC-Col">
                                                <template v-for="row in [0,4,8]">
                                                    <v-row class="EDC-Row" :key="moduleItem.module_code+row" v-if="row < moduleItem.group_list.length">
                                                        <template v-for="col in [0,1,2,3]">
                                                            <v-col class="EDC-Col groupName" align="left" :cols="3" v-if="row+col < moduleItem.group_list.length" :key="moduleItem.group_list[row+col].group_code" :id="'chk'+moduleItem.group_list[row+col].group_code">
                                                                <v-checkbox class="radioClass" v-model="moduleItem.group_list[row+col].isSelected" :label="moduleItem.group_list[row+col].group_name" 
                                                                 @change="manageIntermediate(moduleItem)" hide-details dense/>
                                                            </v-col>
                                                        </template>
                                                    </v-row>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .radioClass >>> label {
        top: 4px !important;
    }

    .mainCard{
        padding: 4px !important;
        margin:0px !important;
        margin-left:16px !important;
    }
    .moduleCard{
        padding:0px !important;
        margin:8px !important;
    }

    .moduleCardTitle{
        font-size: 13px !important;
        padding: 8px !important;
        padding-top:4px !important;
        padding-bottom:0px !important;
        margin: 0px !important;
    }

    .groupName{
        font-size: 12px !important;
        color: black !important;
        padding-left: 8px !important;
    }

    .v-card__subtitle, .v-card__text, .v-card__title{
        padding: 4px !important;
        margin: 0px !important;
    }

</style>
<script>
    export default{
        name:'EdcSecurityGroupComponent',
        props:{
            modulesFromParent:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            defaultSelectedItems:{
                type:Array,
                default:()=>{
                    return []
                }
            }
        },
        data(){
            return{
                moduleList:[]
            }
        },
        computed: {
            sortedModules(){
                // plan is to show all selected modules on top and not selected below. 
                // TODO
                return this.moduleList
            },
            getSelectedModules(){
                var selectedModules = []
                for(let i=0;i<this.moduleList.length;i++){
                    let currentRow = this.moduleList[i]
                    let selectedItems = this.getSelectedItems(currentRow,true)
                    if(!selectedItems.length)
                        continue
                    selectedModules.push({"module_code":currentRow.module_code, "group_list":_.map(selectedItems,"group_code")})
                }
                return selectedModules   
            }
        },
        watch:{
            'modulesFromParent':{
                handler(newValue){
                    this.moduleList = _.cloneDeep(this.modulesFromParent)
                }
            },
            'defaultSelectedItems':{
                handler(newValue){
                    this.manageDefaultSelected(_.cloneDeep(newValue))
                }
            }
        },
        mounted() {
            this.moduleList = _.cloneDeep(this.modulesFromParent)
        },
        methods: {
            manageDefaultSelected(selectedItems){
                var _this = this
                _.forEach(selectedItems,(selectedModule)=>{
                    let moduleItem = _.find(_this.moduleList, ["module_code",selectedModule.module_code])
                    if(!moduleItem)
                        return
                    _.forEach(moduleItem.group_list,(groupObj)=>{
                        if(selectedModule.group_list.indexOf(groupObj.group_code) >-1)
                            groupObj.isSelected = true
                    })
                    _this.manageIntermediate(moduleItem)
                })
            },
            changeSelection(moduleItem){
                _.forEach(moduleItem.group_list,(item)=>{
                    item.isSelected=moduleItem.isSelected
                })
                this.emitSelected()
            },
            clearSelection(moduleItem){
                moduleItem.isSelected = false
                moduleItem.isInterminate = false
                this.changeSelection(moduleItem)
                this.emitSelected()
            },
            manageIntermediate(moduleItem){
                var _this = this
                var selectedItems = this.getSelectedItems(moduleItem)
                
                var defaultItems = _.filter(moduleItem.group_list,(item)=>{
                    return item.is_default === true
                })

                var isDefaultSelection = _.find(defaultItems,['isSelected',true])

                if(selectedItems.length || !_.isEmpty(isDefaultSelection)){
                    _.forEach(defaultItems, (item)=>{
                        _this.$nextTick(()=>{
                            item.isSelected = true
                        })
                    })
                    moduleItem.isInterminate = (selectedItems.length + defaultItems.length)  < moduleItem.group_list.length
                    moduleItem.isSelected = (selectedItems.length + defaultItems.length) === moduleItem.group_list.length
                }else{
                    this.clearSelection(moduleItem)
                }
                this.emitSelected()
            },
            getSelectedItems(moduleItem, includeDefault=false){
                return _.filter(moduleItem.group_list,(item)=>{
                    return item.isSelected === true 
                    && (!item.is_default || (includeDefault && item.is_default===true))
                })
            },
            emitSelected(){
                this.$emit('getSelectedModules',this.getSelectedModules)
            }
        },
    }
</script>