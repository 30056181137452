<template>
  <div id="register">
    <v-container style="padding-top:10px">
      <v-form ref="form" @submit.prevent="submit" v-model="valid">
        <v-row id="loginContainer">
          <v-col cols="4" class="EDC-Col"></v-col>
          <v-col cols="4" class="EDC-Col">
            <v-row style="background: white;padding: 16px;border-radius: 15px" justify="center" align="center">
              <v-col cols="12" class="EDC-Col">               
                  <h4>Hi {{this.email}},</h4>
                  <h6>{{resetMsg || 'Please reset your Password'}}</h6>
              </v-col>
              <v-col cols="10" class="EDC-Col">
                <v-row justify="center">
                  <v-col cols="12" class="EDC-Col">
                    <v-text-field label="Password" autocomplete="off" clearable
                    v-model="password"
                    :append-icon="show1 ? 'visibility_off' : 'visibility'"
                    :type="show1 ? 'text' : 'password'"
                    class="input-group--focused"
                    required
                    :rules="passwordRules"
                    @click:append="show1 = !show1" @keyup="checkwithpassword">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" class="EDC-Col">
                    <v-text-field label="Confirm Password" autocomplete="off" clearable
                    v-model="confirmpassword"
                    :rules="confirmPasswordRules"
                    :append-icon="show1 ? 'visibility_off' : 'visibility'"
                    :type="show1 ? 'text' : 'password'"
                    class="input-group--focused"
                    required
                    @click:append="show1 = !show1" ref='confirmpassword' @keyup="checkwithpassword">
                    </v-text-field>
              </v-col>
                </v-row>
              </v-col>
              <v-col cols="10" class="py-4">
                <vc-button type="submit" :loading="loading" :disabled="loading" itemText="Reset Your Password"></vc-button>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="EDC-Col"></v-col>
        </v-row>
      </v-form>
      <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'
        >
        {{ snackbartext }}
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
 import config from '../../config.json'
  import { post as postToServer } from './../../methods/serverCall.js';
  import { get as getToServer } from './../../methods/serverCall.js';
  import {FORGOT_PASSWORD_MESSAGE, reset_password_message} from '../../data/client_message.js'
 import {RESET_PASSWORD, FORGOT_PASSWORD_VERIFY_TOKEN} from '../../data/url_constants.js'
 export default {
  data() {
      return {
        email:'',
        password: '',
        confirmpassword: '',
        passwordFieldType: 'password',
        show1: false,
        valid:true,
        snackbar:false,
        snackbartext:'',
        colorValue:'',
        loader: "loading",
        loading: false,
        resetMsg:'',
        snackbartimeout:5000,
        passwordRules: [v => !!v || 'Password is required.'],
        confirmPasswordRules: [v => !!v || 'Confirm Password is required.',
        v=> v == this.password || 'Password and Confirm Password should be same'],

      }
  },
  mounted() {
      //this.is_forgot_password_email()
      if(this.isResetLogin){
        this.resetMsg = this.$route.params.resetMsg
        this.email = this.$route.params.email.toLowerCase()
      }
      else
        this.forgot_password_token_check()
  
  },
  computed:{
    isResetLogin(){
      return this.$route.params && this.$route.params.isResetAndLogin
    }
  },
  props: {
    msg: String
  },
  methods: {
    checkwithpassword(){
      this.$refs.confirmpassword.validate()
    },

    is_forgot_password_email(){
       if(this.$cookies.isKey('forgot_password_email')){
        this.email = this.$cookies.get('forgot_password_email')
        this.$cookies.remove('forgot_password_email')
        this.snackbar = true
        this.colorValue = 'info'
        this.snackbartext = FORGOT_PASSWORD_MESSAGE;
      }
    },

    forgot_password_token_check(){
      var forgot_password_token_endpoint = config.USER_PROVISION_URL + FORGOT_PASSWORD_VERIFY_TOKEN + this.$route.query.token
      getToServer(this, forgot_password_token_endpoint, true).then(user_provisioning_response => {
          this.email = user_provisioning_response
      }).catch(error_response => {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = "The token is invalid. Please click on 'Forgot Password' on the login screen again."
          this[l]  = false
          this.loader = null 
      });
    },

    submit () {
      if (this.$refs.form.validate()) {
          this.reset_password()
      }
    },
    reset_password(){
      const l = this.loader
      this[l] = !this[l]
      var reset_password_details = {
        "username":this.email,
        "new_password":this.password
      }
      var user_provisioning_end_point = 
      config.USER_PROVISION_URL + RESET_PASSWORD
      postToServer(this, user_provisioning_end_point, reset_password_details, true).then(user_provisioning_response => {
        if(this.isResetLogin){
          this.$router.push({name:"Login",params:{'isResetAndLogin':true,'username':this.email,'password':this.password}})
          return
        }
        else{
          this.$cookies.set('reset_password_success', true)
          this.$router.push({name:"Login",params:{'isResetAndLogin':true,'username':this.email,'password':this.password}})
        }
      }).catch(error_response => {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response
          this[l]  = false
          this.loader = null             
      });
    }
  }
}
</script>