import { render, staticRenderFns } from "./object_list_for_pkg.vue?vue&type=template&id=25adbd26&scoped=true"
import script from "./object_list_for_pkg.vue?vue&type=script&lang=js"
export * from "./object_list_for_pkg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25adbd26",
  null
  
)

export default component.exports